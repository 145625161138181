import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import Education from './education.component';
import Jobs from './experience.component';
import Introduction from './introduction.component';
import Footer from './footer.component';
import Muse from './muse.component';
import Skeleton from '@material-ui/lab/Skeleton';


export default function About(props) {

    
    var endpoint = "https://tanmoysg.com/backend/connect"
    var pageStyle = props.pagestyle;

    const [education, setEducation] = useState();
    const [experience, setExperience] = useState();
    const [profile, setProfile] = useState(props.profile);

    useEffect(() => {
        setProfile(props.profile);
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "get-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "education"
                }
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            var education = json.data;
            setEducation(education);
        });

        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "get-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "experience"
                }
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            var experience = json.data;
            setExperience(experience);
        });
        /*return () => {
            cleanup
        }
        */
    }, [])

    return (
        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="column" spacing={3}>
                    <Grid item style={{ marginTop: "15px" }}>
                        <Introduction pagestyle={pageStyle} profile={profile} />
                    </Grid>
                    <Grid item >
                        <Muse pagestyle={pageStyle} data={profile}/>
                    </Grid>
                    {
                        education && experience ?
                            <>
                                <Grid item style={{ marginTop: "15px" }}>
                                    {education !== undefined ? <Education data={education} cardstyle={pageStyle} /> : undefined}
                                </Grid>
                                <Grid item style={{ marginTop: "15px" }}>
                                    {experience !== undefined ? <Jobs data={experience} cardstyle={pageStyle} /> : undefined}
                                </Grid>
                            </> : <Skeleton variant="rect" style={{ width: "100%" }} height={100} />
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Footer pagestyle={pageStyle} />
            </Grid>
        </Grid>
    );
}