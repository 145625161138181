import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faUniversity, faSchool, faChevronCircleDown, faInfoCircle, faTimesCircle, faRibbon, faBook, faBinoculars, faAward, faBookOpen, faBriefcase, faUserAstronaut, faAt, faCertificate } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Button, Dialog, DialogActions, DialogTitle, DialogContent, Hidden, Paper, Chip, Link } from "@material-ui/core";

export default function Jobs(props) {

    const [experiences, setExperiences] = useState();

    useEffect(() => {
        var jobs = [];
        Object.keys(props.data).map((job, e) => {
            delete props.data[job]['_id'];
            jobs.splice(props.data[job]['displayPriority'], 0, props.data[job]);
            //jobs.push(props.data[job])
        })
        setExperiences(jobs.reverse());
        /*
        return () => {
            cleanup
        }*/
    }, [])

    const pageStyle = props.cardstyle;

    const [open, setOpen] = React.useState('none');

    console.log(experiences)

    return (
        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 15px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}>
                    <FontAwesomeIcon icon={faBriefcase} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Work Experience
                </Typography>
                <Grid container direction="row" alignItems="stretch" justify="flex-start" spacing={2}>
                    {
                        experiences ? experiences.map((education) => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <Paper style={{ padding: '20px 25px 20px 25px', borderRadius: '7.5px', fontFamily: 'Work Sans', backgroundColor: pageStyle.backgroundColor, height: '100%', width: '100%' }} elevation={3}>
                                        <Grid container direction="column" alignItems="center" justify="space-between" spacing={1}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FontAwesomeIcon icon={faUserAstronaut} style={{ fontSize: "2.25rem", height: '2.25rem', width: "2.25rem", padding: "0.5rem", color: pageStyle.accentColor, border: "solid 1.5px " + pageStyle.foregroundColor, borderRadius: "7.5px" }} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography style={{ textAlign: "center", fontSize: "17.5px", lineHeight: "1", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                    {education.role}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography style={{ textAlign: "center", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                    <FontAwesomeIcon icon={faAt} style={{ color: pageStyle.accentColor }} /> {education.company}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Chip variant="outlined" label={
                                                    <Typography style={{ textAlign: "center", fontSize: '0.8125rem', fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                        {education.period} <span style={{ color: pageStyle.accentColor }}> | </span> {education.duration}
                                                    </Typography>}
                                                    style={{ backgroundColor: pageStyle.backgroundColor, borderRadius: "7px", boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.43)' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography style={{ marginTop: "10px", textAlign: "justify", fontSize: "12.5px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                    {education.details}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: "10px" }}>
                                                {education.certlink !== 'NULL' ? <Link target="_blank" underline="none" href={education.certlink} style={{ color: pageStyle.foregroundColor }} ><FontAwesomeIcon icon={faCertificate} style={{ marginRight: '2px', color: pageStyle.accentColor }} /> Certificate </Link> : undefined}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            );
                        }) : undefined
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}