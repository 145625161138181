import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faComment, faInbox, faLaptopCode, faAt, faSignature, faQuoteLeft, faQuoteRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Grid, Typography, Paper, Link, FormControl, InputAdornment, Button, OutlinedInput, Snackbar, Hidden, Divider } from "@material-ui/core";
import WebFeedback from './feedback.component';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';


export default function Contacts(props) {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    
    var endpoint = "https://tanmoysg.com/backend/connect"

    var currentTime = new Date();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var messageTimestamp = currentTime.getDate() + " " + months[currentTime.getMonth()] + ", " + currentTime.getFullYear() + " @ " + currentTime.toLocaleTimeString();

    const [data, setData] = useState({
        messageDateTime: messageTimestamp,
        replyStatus: "New Message - Not Replied",
        senderEmail: "",
        senderMessage: "",
        senderName: ""
    });

    const handleSubmit = (e) => {
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "add-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "messages",
                    "data": data
                }
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            handleClick();
            setData({
                messageDateTime: messageTimestamp,
                replyStatus: "New Message - Not Replied",
                senderEmail: "",
                senderMessage: "",
                senderName: ""
            });
        })
    }

    var pageStyle = props.pagestyle;
    if (data.senderMessage.toLowerCase() === "go_purple" || data.senderMessage.toLowerCase() === "purple" ) {
        pageStyle = {
            backgroundColor: "#080711",
            foregroundColor: "#bbb7df",
            accentColor: "#6a5ce9",
        }
    };

    const [socials, setSocials] = useState();

    const socialStyles = {
        mail: { icon: faAt },
        linkedin: { icon: faLinkedin },
        github: { icon: faGithub },
        code: { icon: faLaptopCode },
        others: { icon: faShare }
    };

    useEffect(() => {
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "get-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "social"
                }
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            var data = json.data;
            var mails = [];
            var social = [];
            var code = [];
            var linkedin = [];
            var github = [];
            var others = [];
            Object.keys(data).forEach(function (key) {
                if (data[key].service.toString().toLowerCase().includes('mail')) {
                    delete data[key]['_id'];
                    mails.push({ type: 'mail', ...data[key] });
                } else if (data[key].service.toString().toLowerCase().includes('hack') || data[key].service.toString().toLowerCase().includes('code')) {
                    delete data[key]['_id'];
                    code.push({ type: 'code', ...data[key] });
                } else if (data[key].service.toString().toLowerCase().includes('linkedin')) {
                    delete data[key]['_id'];
                    linkedin.push({ type: 'linkedin', ...data[key] });
                } else if (data[key].service.toString().toLowerCase().includes('github')) {
                    delete data[key]['_id'];
                    github.push({ type: 'github', ...data[key] });
                } else {
                    delete data[key]['_id'];
                    others.push({ type: 'others', ...data[key] });
                }
            });
            social = [...mails, ...linkedin, ...github, ...code, ...others];
            setSocials(social);
        });
        /*return () => {
            cleanup
        }
        */
    }, [])

    return (
        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 15px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}>
                    <FontAwesomeIcon icon={faInbox} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Contact Me
                </Typography>
                {
                    socials !== undefined ?
                        socials.map((social) => {
                            if (social !== undefined) {
                                if (social.type === 'linkedin' || social.type === 'github') {
                                    return (
                                        <Typography style={{ fontSize: "1rem", margin: "8.5px 0px 8.5px 0px", color: pageStyle.foregroundColor, fontFamily: "Work Sans" }}>
                                            <FontAwesomeIcon icon={social.type === 'github' ? socialStyles.github.icon : social.type === 'linkedin' ? socialStyles.linkedin.icon : undefined} style={{ width: "1.5rem", marginRight: '3px', color: pageStyle.accentColor }} />
                                            <Link target="_blank" href={social.link} underline="none" style={{ color: pageStyle.foregroundColor }}>
                                                {social.service}
                                                <span style={{ color: pageStyle.accentColor, fontWeight: "800" }}> / </span>
                                                <span style={{ padding: "3px 6px 3px 6px", backgroundColor: pageStyle.backgroundColor, borderRadius: "5px" }}>{social.username}</span>
                                            </Link>
                                        </Typography>
                                    );
                                } else if (social.type === 'mail') {
                                    return (
                                        <Typography style={{ margin: "8.5px 0px 8.5px 0px", color: pageStyle.foregroundColor, fontFamily: "Work Sans" }} >
                                            <FontAwesomeIcon icon={socialStyles.mail.icon} style={{ width: '1.5rem', marginRight: '3px', color: pageStyle.accentColor }} />
                                            <Link target="_blank" href={social.link} underline="none" style={{ color: pageStyle.foregroundColor }}>
                                                <span style={{ padding: "3px 6px 3px 6px", backgroundColor: pageStyle.backgroundColor, borderRadius: "5px" }}>{social.username}</span>
                                            </Link>
                                        </Typography>
                                    );
                                } else if (social.type === 'code') {
                                    return (
                                        <Typography style={{ margin: "8.5px 0px 8.5px 0px", color: pageStyle.foregroundColor, fontFamily: "Work Sans" }}>
                                            <FontAwesomeIcon icon={socialStyles.code.icon} style={{ width: "1.5rem", marginRight: '3px', color: pageStyle.accentColor }} />
                                            <Link target="_blank" href={social.link} underline="none" style={{ color: pageStyle.foregroundColor }}>
                                                {social.service} <span style={{ color: pageStyle.accentColor, fontWeight: "800" }}> / </span>
                                                <span style={{ padding: "3px 6px 3px 6px", backgroundColor: pageStyle.backgroundColor, borderRadius: "5px" }}>{social.username}</span>
                                            </Link>
                                        </Typography>
                                    );
                                } else {
                                    return (
                                        <Typography style={{ margin: "8.5px 0px 8.5px 0px", color: pageStyle.foregroundColor, fontFamily: "Work Sans" }}>
                                            <FontAwesomeIcon icon={socialStyles.others.icon} style={{ width: "1.5rem", marginRight: '3px', color: pageStyle.accentColor }} />
                                            <Link target="_blank" href={social.link} underline="none" style={{ color: pageStyle.foregroundColor }}>
                                                {social.service} <span style={{ color: pageStyle.accentColor, fontWeight: "800" }}> / </span>
                                                <span style={{ padding: "3px 6px 3px 6px", backgroundColor: pageStyle.backgroundColor, borderRadius: "5px" }}>{social.username}</span>
                                            </Link>
                                        </Typography>
                                    );
                                }
                            }
                        }) :
                        <>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </>
                }
                {/*Large Screen Devices*/}
                <Hidden smDown>
                    {/* Feedback Block */}
                    {/*}
                    <Divider style={{ margin: '20px 20px 15px 0px', borderTop: "1px dashed" + pageStyle.accentColor, borderBottom: "none", borderRight: "none", borderLeft: "none" }} />
                    {*/}
                    <div style={{ margin: '15px 20px 15px 0px' }}>
                        <WebFeedback pagestyle={pageStyle} />
                    </div>
                    {/* Feedback Block */}
                </Hidden>
                {/*Large Screen Devices*/}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 15px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}>
                    <FontAwesomeIcon icon={faComment} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Leave a message
                </Typography>
                <Paper style={{ padding: '30px', borderRadius: '7.5px', fontFamily: 'Work Sans', backgroundColor: pageStyle.backgroundColor, width: '100%' }} elevation={3}>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl style={{ width: "100%" }}>
                                    <label style={{ marginBottom: "3px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }} >
                                        Name
                                    </label>
                                    <OutlinedInput
                                        id="Name"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faSignature} style={{ marginRight: '5px', color: pageStyle.accentColor }} />
                                            </InputAdornment>
                                        }
                                        style={{ fontFamily: "Work Sans", fontWeight: "300" }}
                                        value={data.senderName}
                                        onChange={e => setData({ ...data, senderName: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl style={{ width: "100%" }}>
                                    <label style={{ marginBottom: "3px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }} >
                                        Email
                                    </label>
                                    <OutlinedInput
                                        id="Email"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAt} style={{ marginRight: '5px', color: pageStyle.accentColor }} />
                                            </InputAdornment>
                                        }
                                        style={{ fontFamily: "Work Sans", fontWeight: "300" }}
                                        value={data.senderEmail}
                                        onChange={e => setData({ ...data, senderEmail: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl style={{ width: "100%" }}>
                                    <label style={{ marginBottom: "3px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }} >
                                        Your Message
                                    </label>
                                    <OutlinedInput
                                        id="Message"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faQuoteLeft} style={{ marginRight: "3px", color: pageStyle.accentColor }} />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faQuoteRight} style={{ marginLeft: "3px", color: pageStyle.accentColor }} />
                                            </InputAdornment>
                                        }
                                        multiline
                                        style={{ fontFamily: "Work Sans", fontWeight: "300" }}
                                        value={data.senderMessage}
                                        onChange={e => setData({ ...data, senderMessage: e.target.value })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button onClick={handleSubmit} variant="outlined" size="large" style={{ color: pageStyle.foregroundColor, fontFamily: "Work Sans", width: "100%" }}>
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "5px", color: pageStyle.accentColor }} />
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                {/*Small Screen Devices*/}
                <Hidden mdUp>
                    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
                        {/* Feedback Block */}
                        <WebFeedback pagestyle={pageStyle} />
                        {/* Feedback Block */}
                    </div>
                </Hidden>
                {/*Small Screen Devices*/}
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info">
                        Message Sent
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}
