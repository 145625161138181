import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faFileContract, faInbox, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Grid, Typography, Paper, Link } from "@material-ui/core";
import dp from "../assets/dp.jpg";

export default function IDCard(props) {

    const pageStyle = props.pagestyle;
    
    const [profile, setProfile] = useState(props.profile);

    return (
        <Paper variant="outlined" style={{ borderWidth: "1.5px", borderRadius: "10px", padding: "20px", backgroundColor: pageStyle.backgroundColor, margin: "15px", boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.75)' }} elevation={3}>
            <Grid container direction="column" justify="space-around" alignItems="center" spacing={2}>
                <Grid item style={{ width: "100%" }}>
                    <Grid container direction="row" justify="space-around" alignItems="center" spacing={2} >
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}><Paper variant="outlined" style={{ backgroundColor: "#1c1c1e", width: "100%", borderRadius: "5px", padding: "5px" }}></Paper></Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ margin: '10px 0 10px 0' }}>
                    <Grid container direction="row" justify="space-around" alignItems="centre">
                        <img src={dp} style={{ height: "125px", width: "125px", borderRadius: "125px" }} />
                    </Grid>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <Typography style={{ textAlign: "center", lineHeight: '1', color: pageStyle.accentColor, fontFamily: 'Work Sans', fontSize: "1.5rem", fontWeight: "400" }}>
                        Tanmoy Sen Gupta
                    </Typography>
                    <Typography style={{ textAlign: "center", color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.875rem", fontWeight: "400" }}>
                        {profile ? profile.currentRole : undefined} <FontAwesomeIcon icon={faAt} style={{ fontStyle: 'italic', color: pageStyle.accentColor }} /> <span style={{ color: pageStyle.accentColor, fontWeight: "500" }}>{profile.currentOrg} </span>
                    </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <Typography style={{ textAlign: "center", color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.75rem", fontWeight: "400" }}>
                        {profile ? <> <FontAwesomeIcon icon={faPhone} style={{ color: pageStyle.accentColor }} />  {profile.primaryPhone} </> : undefined}
                    </Typography>
                    <Typography style={{ textAlign: "center", color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.75rem", fontWeight: "400" }}>
                        {profile ? <> <FontAwesomeIcon icon={faInbox} style={{ color: pageStyle.accentColor }} />  {profile.primaryMail} </> : undefined}
                    </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <Typography style={{ textAlign: "center", color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.75rem", fontWeight: "400" }}>
                        {profile ? <> <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: pageStyle.accentColor }} />  {profile.currentLoc} </> : undefined}
                    </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    {/*}
                    <Typography style={{ textAlign: "center", color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.875rem", fontWeight: "500" }}>
                        <Link href="https://drive.google.com/file/d/1wJFa2us-I_2y01iauJXfFfra5Pp-EXSK/view?usp=sharing" underline="none" target="_blank" style={{ color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.875rem", fontWeight: "500" }}> Download Resume<FontAwesomeIcon icon={faFileContract} style={{ fontSize: "0.875rem", color: pageStyle.accentColor, marginLeft: "5px" }} /></Link>
                    </Typography>
                    {*/}
                    <Typography style={{ textAlign: "center", color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "0.875rem", fontWeight: "400" }}>
                        Catch me on
                        <Link href='https://linkedin.com/in/tanmoysg' underline="none" target="_blank"><FontAwesomeIcon icon={faLinkedin} style={{ fontSize: "0.875rem", color: pageStyle.accentColor, marginLeft: "5px" }} /></Link>
                        <Link href='https://github.com/TanmoySG' underline="none" target="_blank"><FontAwesomeIcon icon={faGithub} style={{ fontSize: "0.875rem", color: pageStyle.accentColor, marginLeft: "5px" }} /></Link>
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}