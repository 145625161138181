import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint, faTerminal, faLayerGroup, faGlobeAsia, faRocket, faBookmark, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { Button, Grid, Typography, Hidden, Paper, Link } from "@material-ui/core";

export default function ProjectCard(props) {

    const projects_style = {
        web: {
            icon: faGlobeAsia,
            color: ''
        },
        cryptography: {
            icon: faFingerprint,
            color: ''
        },
        mlai: {
            icon: faTerminal,
            color: ''
        },
        others: {
            icon: faLayerGroup,
            color: ''
        }
    }

    const cardData = props.data;
    const cardStyle = props.cardstyle;

    return (
        <Paper style={{ padding: '20px', borderRadius: '7.5px', fontFamily: 'Work Sans', backgroundColor: cardStyle.backgroundColor, height: '100%', width: '100%' }} elevation={3}>
            <Grid container direction="column" justify="space-around" alignItems="flex-start" spacing={2}>
                <Grid item>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid container direction="column" justify="space-between" alignItems="flex-start">
                            <Typography style={{ fontFamily: 'Work Sans', fontSize: '1.2rem', color: cardStyle.foregroundColor, marginBottom: '5px', fontWeight: "400" }}>{cardData.name}</Typography>
                            {
                                cardData.domain.toString().toLowerCase().includes('web') ? <Typography style={{ fontFamily: 'DM Sans', fontSize: '.85rem' }}><FontAwesomeIcon icon={projects_style['web'].icon} style={{ marginRight: '2.5px', color: cardStyle.accentColor }} /> {cardData.domain} </Typography>
                                    : cardData.domain.toString().toLowerCase().includes('cryptography') ? <Typography style={{ fontFamily: 'DM Sans', fontSize: '.85rem' }}><FontAwesomeIcon icon={projects_style['cryptography'].icon} style={{ marginRight: '2.5px', color: cardStyle.accentColor }} /> {cardData.domain} </Typography>
                                        : cardData.domain.toString().toLowerCase().includes('machine') ? <Typography style={{ fontFamily: 'DM Sans', fontSize: '.85rem' }}><FontAwesomeIcon icon={projects_style['mlai'].icon} style={{ marginRight: '2.5px', color: cardStyle.accentColor }} /> {cardData.domain} </Typography>
                                            : <Typography style={{ fontFamily: 'DM Sans', fontSize: '.85rem' }}><FontAwesomeIcon icon={projects_style['others'].icon} style={{ marginRight: '2.5px', color: cardStyle.accentColor  }} /> {cardData.domain} </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography style={{ fontFamily: 'DM Sans', fontSize: '.75rem' }}>{cardData.details}</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontFamily: 'DM Sans', fontSize: '.75rem' }}>{cardData.stack}</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontFamily: 'DM Sans', fontSize: '1rem' }}>
                        {cardData.articlelink !== 'NULL' ? <Link target="_blank" underline="none" href={cardData.articlelink} ><FontAwesomeIcon icon={faBookmark} style={{ marginRight: '10px', color: cardStyle.accentColor }} /> </Link> : undefined}
                        {cardData.codelink !== 'NULL' ? <Link target="_blank" underline="none" href={cardData.codelink} > <FontAwesomeIcon icon={faCodeBranch} style={{ marginRight: '10px', color: cardStyle.accentColor }} /></Link> : undefined}
                        {cardData.demolink !== 'NULL' ? <Link target="_blank" underline="none" href={cardData.demolink} ><FontAwesomeIcon icon={faRocket}  style={{ marginRight: '10px', color: cardStyle.accentColor }} />  </Link> : undefined}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}