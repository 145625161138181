import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCubes, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { Grid, Typography, Paper, Link, Chip } from "@material-ui/core";
import ProjectCard from "./project-card.component";
import Skeleton from '@material-ui/lab/Skeleton';


export default function Projects(props) {

    const pageStyle = props.pagestyle;
    

    var endpoint = "https://tanmoysg.com/backend/connect"

    const [projects, setProjects] = useState();
    const [filter, setFilter] = useState('All')
    const [categories, setCategories] = useState();

    useEffect(() => {
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "get-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "project"
                }
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            var data = json.data;
            var projects_data = [];
            var categories = ['All'];
            Object.keys(data).forEach(function (key) {
                if (!(data[key].domain in categories)) {
                    categories.push(data[key].domain);
                }
            });
            Object.keys(data).forEach(function (key) {
                projects_data.splice(data[key]['displayPriority'], 0, data[key]);
                //projects_data.push(data[key]);
            });
            setProjects(projects_data.reverse());
            categories = [...new Set(categories)];
            setCategories(categories)
        });
        /*return () => {
            cleanup
        }
        */
    }, [])

    return (
        <Grid container direction="column" alignItems="flex-start" justify="center" spacing={3} style={{ marginTop: '5px' }}>
            <Grid item style={{ width: '100%' }}>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 10px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}><FontAwesomeIcon icon={faCubes} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Projects</Typography>
                        {
                            categories !== undefined ?
                                categories.map((category) => {
                                    if (category === filter) {
                                        return (
                                            <Chip label={
                                                <Typography style={{ fontSize: '0.8125rem', fontFamily: 'Work Sans', color: pageStyle.foregroundColor, }}><FontAwesomeIcon variant="subtitle2" icon={faCheck} style={{ color: pageStyle.accentColor }} /> {category}</Typography>
                                            } onClick={() => setFilter(category)} style={{ fontSize: '0.8125rem', marginRight: '10px', marginTop: '5px', marginBottom: '5px', fontFamily: 'Work Sans', color: pageStyle.foregroundColor, backgroundColor: pageStyle.backgroundColor, borderRadius: "9px", boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.43)' }} />
                                        );
                                    } else {
                                        return (
                                            <Chip variant="outlined" label={category} onClick={() => setFilter(category)} style={{ fontSize: '0.8125rem', marginRight: '10px', marginTop: '5px', marginBottom: '5px', fontFamily: 'Work Sans', borderRadius: "9px", boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.43)' }} />
                                        );
                                    }

                                }) : <Chip variant="outlined" label='All' onClick={() => setFilter('All')} style={{ fontSize: '0.8125rem', margin: '5px', fontFamily: 'Work Sans', color: pageStyle.foregroundColor, backgroundColor: pageStyle.backgroundColor, borderRadius: "7px" }} />
                        }
                        <Typography style={{ fontSize: '1rem', margin: '20px 0px 0px 0px', fontFamily: 'Work Sans' }}>Find all my Projects on  <Link target="_blank" underline="none" href="https://github.com/TanmoySG?tab=repositories" ><Chip variant="outlined" label={<><FontAwesomeIcon icon={faGithub} style={{ color: pageStyle.accentColor }} /> Github </>} style={{ backgroundColor: pageStyle.backgroundColor, fontSize: '.85rem', color: pageStyle.foregroundColor, fontFamily: 'Work Sans', borderRadius: "9px", boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.43)' }} /></Link></Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Paper style={{ padding: '20px', borderRadius: '7.5px', fontFamily: 'Work Sans', backgroundColor: pageStyle.backgroundColor }} elevation={3}>
                            <Typography style={{ fontSize: '1rem', fontFamily: "Work Sans" }}>
                                <FontAwesomeIcon icon={faHandsHelping} style={{ marginRight: '5px', color: pageStyle.accentColor }} />
                                Open to <span style={{ fontWeight: '500', color: pageStyle.foregroundColor }}>collaborations!</span>
                            </Typography>
                            <Typography style={{ fontSize: '0.8rem', fontFamily: "Work Sans" }}>
                                Mail me at <Link target="_blank" style={{ color: pageStyle.foregroundColor }} href="mailto:mail@tanmoysg.com?subject=Project Collaboration" >mail@tanmoysg.com</Link>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ width: "100%" }}>
                <Grid container direction="row" alignItems="stretch" justify="center" spacing={5}>
                    {
                        projects !== undefined ?
                            projects.map((project) => {
                                if (filter !== 'All') {
                                    if (project.domain === filter) {
                                        return (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                <ProjectCard data={project} cardstyle={pageStyle} />
                                            </Grid>
                                        );
                                    } else { }
                                } else if (filter === 'All') {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <ProjectCard data={project} cardstyle={pageStyle} />
                                        </Grid>
                                    );
                                }
                            })
                            : undefined
                    }
                </Grid>
                {
                    projects ? undefined :
                        <Grid container direction="row" alignItems="stretch" justify="center" spacing={5}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                <Skeleton animation="wave" height={100} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                <Skeleton animation="wave" height={100} />
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Grid>

    );
}