import React from 'react';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import './App.css';
import styles from './assets/custom-styling.css';
import GoogleFontLoader from 'react-google-font-loader';
import { Container, Grid, Typography, Hidden, Paper } from "@material-ui/core";
import Emoji from "./components/emoji.component";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Home from './components/home.component';
import Projects from './components/projects.component';
import Contacts from './components/contact.component';
import About from './components/about.component';

function App() {

	var endpoint = "https://tanmoysg.com/backend/connect"

	const PAGES = {
		home: 'home',
		about: 'about',
		projects: 'projects',
		contacts: 'contacts'
	};

	const pageStyles = {
		home: {
			label: "Home",
			backgroundColor: "#030e18",
			foregroundColor: "#a6d5fa",
			accentColor: "#2196f3",
			navigatorButton: <Emoji symbol="👋" label="Hi!" />
		},
		projects: {
			label: "Projects",
			backgroundColor: "#180605",
			foregroundColor: "#fab3ae",
			accentColor: "#f44336",
			navigatorButton: <Emoji symbol="💻" label="Try!" />
		},
		contacts: {
			label: "Contact Me",
			backgroundColor: "#071107",
			foregroundColor: "#b7dfb9",
			accentColor: "#3faf62",
			navigatorButton: <Emoji symbol="📮" label="Ping!" />
		},
		about: {
			label: "About",
			backgroundColor: "#190f00",
			foregroundColor: "#ffd599",
			accentColor: "#ff9800",
			navigatorButton: <Emoji symbol="🤖" label="Me!" />
		},
		others: {
			label: "Others",
			backgroundColor: "#080711",
			foregroundColor: "#bbb7df",
			accentColor: "#6a5ce9",
			navigatorButton: <Emoji symbol="⚡" label="Ping!" />
		}

	}

	const [pageStyle, setPageStyle] = React.useState(pageStyles.home)
	const [profileInfo, setProfileInfo] = React.useState();
	const [page, setPage] = React.useState(PAGES.home);

	const fetchProfile = () => {
		fetch(endpoint, {
			method: "POST",
			cache: "no-cache",
			headers: {
				"content_type": "application/json",
			},
			body: JSON.stringify({
				"action": "get-data",
				"payload": {
					"database": "tsgOnWebData",
					"collection": "profileSpotlight"
				}
			})
		}).then(res => {
			return res.json();
		}).then(json => {
			var spot = json.data;
			Object.keys(spot).map((id) => {
				setProfileInfo(spot[id]);
			})
		});
	}

	function changePage(toPage) {
		if (toPage === 'home') {
			setPage(PAGES.home);
			setPageStyle(pageStyles.home);
		} else if (toPage === 'projects') {
			setPage(PAGES.projects);
			setPageStyle(pageStyles.projects);
		} else if (toPage === 'about') {
			setPage(PAGES.about);
			setPageStyle(pageStyles.about);
		} else if (toPage === 'contacts') {
			setPage(PAGES.contacts);
			setPageStyle(pageStyles.contacts);
		}
	}

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
		fetchProfile();
	}, [open]);


	let theme = createMuiTheme({
		palette: {
			type: 'dark',
			background: {
				default: "#1C1C1E"
			}
		},
	});

	console.log(profileInfo);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GoogleFontLoader
				fonts={[
					{
						font: 'Montserrat',
						weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
					},
					{
						font: 'Raleway',
						weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
					},
					{
						font: 'Work Sans',
						weights: [0, 100, 0, 200, 0, 300, 0, 400, 0, 500, 0, 531, 0, 600, 0, 700, 0, 800, 1, 531],
					},
					{
						font: 'DM Sans',
						weights: [400, 500, 600, 700, 800, 900],
					},
					{
						font: 'Poppins',
						weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
					}
				]}
			/>
			<Container maxWidth="false"  >
				<Grid container direction="row" alignItems="center" justify="center" style={{ height: "85vh", marginTop: "7.5vh", marginBottom: "7.5vh" }}>
					<Hidden smDown>
						<Grid item md={2} lg={2} xl={2}></Grid>
					</Hidden>
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						<Grid container direction="row" alignItems="center" >
							<Grid container direction="row" alignItems="center" >
								<Paper
									ref={anchorRef}
									aria-controls={open ? 'menu-list-grow' : undefined}
									aria-haspopup="true"
									onClick={handleToggle}
									style={{ cursor: "pointer", paddingTop: '7px', paddingBottom: '7px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "20px" }}
									elevation={3}
								>
									<Grid container direction="row" alignItems="center">
										{
											pageStyle.navigatorButton
										}
										<Typography variant="subtitle1" style={{ marginLeft: '5px', fontFamily: 'Work Sans' }} >tanmoysg  <span style={{ color: pageStyle.accentColor, fontWeight: "800" }}> / </span>  {page === PAGES.home ? 'hello, world!' : page}</Typography>
									</Grid>
								</Paper>
								<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: '100' }}>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
										>
											<Paper elevation={3} style={{ backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "7px", marginTop: '5px' }}>
												<ClickAwayListener onClickAway={handleClose}>
													<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
														{
															Object.keys(PAGES).map(function (page, p) {
																return (
																	<MenuItem onClick={() => changePage(PAGES[page])}>{pageStyles[PAGES[page]].navigatorButton}<Typography variant="subtitle1" style={{ marginLeft: '5px', fontFamily: "Work Sans" }} >{pageStyles[PAGES[page]].label}</Typography></MenuItem>
																);
															})
														}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</Grid>
							<Grid container style={{ marginTop: "20px" }}>
								{
									page === PAGES.home && profileInfo !== undefined ? <Home pagestyle={pageStyle} profile={profileInfo} /> :
										page === PAGES.projects ? <Projects pagestyle={pageStyle} /> :
											page === PAGES.about && profileInfo !== undefined ? <About pagestyle={pageStyle} profile={profileInfo} /> :
												page === PAGES.contacts ? <Contacts pagestyle={pageStyle} /> : <Home pagestyle={pageStyle} />
								}
							</Grid>
						</Grid>
					</Grid>
					<Hidden smDown>
						<Grid item md={2} lg={2} xl={2}></Grid>
					</Hidden>
				</Grid>
			</Container>
		</ThemeProvider>
	);

}

export default App;