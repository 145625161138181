import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography, Hidden, } from "@material-ui/core";
import IDCard from './idcard.component';
import Skeleton from '@material-ui/lab/Skeleton';
import SkillPopTarts from './skills.component';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

export default function Introduction(props) {

    const pageStyle = props.pagestyle;
    

    var endpoint = "https://tanmoysg.com/backend/connect"

    const [skills, setSkills] = useState();
    const [profile, setProfile] = useState(props.profile);

    useEffect(() => {
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "get-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "skills"
                }
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            var skill = {};
            var skills = [];
            Object.keys(json.data).forEach(function (key) {
                delete json.data[key]['_id'];
                if (json.data[key].type in skill) {
                    if (json.data[key].expertise in skill[json.data[key].type]) {
                        skill[json.data[key].type][json.data[key].expertise].push(json.data[key])
                    } else {
                        skill[json.data[key].type][json.data[key].expertise] = [];
                        skill[json.data[key].type][json.data[key].expertise].push(json.data[key])
                    }

                } else {
                    skill[json.data[key].type] = {};
                    if (json.data[key].expertise in skill[json.data[key].type]) {
                        skill[json.data[key].type][json.data[key].expertise].push(json.data[key])
                    } else {
                        skill[json.data[key].type][json.data[key].expertise] = [];
                        skill[json.data[key].type][json.data[key].expertise].push(json.data[key])
                    }
                }
            });
            Object.keys(skill).map((type) => {
                Object.keys(skill[type]).map((proficiency) => {
                    skill[type][proficiency].map((s) => {
                        skills.push(s)
                    })
                })
            })
            setSkills(skills);
        });

        /*return () => {
            cleanup
        }*/
    }, [])

    console.log(skills);

    return (
        <div>
            <Grid container direction="row" alignItems="centre" justify="space-between" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Typography variant="h4" style={{ fontFamily: 'Work Sans' }}>I am <span style={{ color: pageStyle.foregroundColor, fontWeight: "500", backgroundImage: "linear-gradient(" + pageStyle.backgroundColor + "," + pageStyle.backgroundColor + ")", backgroundRepeat: 'no-repeat', backgroundSize: '100% 0.6em ', backgroundPosition: '0 88%' }}>Tanmoy Sen Gupta.</span></Typography>
                    <Typography style={{ color: "#cdcdcd", fontFamily: 'Work Sans', marginTop: "10px", textAlign: "justify", fontSize: "17.5px" }}>
                        {profile.profileIntro}
                    </Typography>
                    <Hidden smDown>
                        <div style={{ marginTop: "15px" }}>
                            <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 10px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}>
                                <FontAwesomeIcon icon={faBolt} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Skills
                            </Typography>
                            {
                                skills ?
                                    skills.map((skill) => {
                                        return (
                                            <SkillPopTarts poptartstyle={pageStyle} poptartdata={skill} />
                                        );
                                    })
                                    : <Skeleton variant="rect" style={{ width: "100%" }} height={75} animation="wave" />
                            }
                        </div>
                    </Hidden>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                    <IDCard pagestyle={pageStyle} profile={profile} />
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                        <div style={{ marginTop: "15px" }}>
                            <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 10px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}>
                                <FontAwesomeIcon icon={faBolt} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Skills
                            </Typography>
                            {
                                skills ?
                                    skills.map((skill) => {
                                        return (
                                            <SkillPopTarts poptartstyle={pageStyle} poptartdata={skill} />
                                        );
                                    })
                                    : <Skeleton variant="rect" style={{ width: "100%" }} height={75}  animation="wave" />
                            }
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}