import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudSun } from '@fortawesome/free-solid-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { Typography } from "@material-ui/core";


export default function Footer(props) {

    const pageStyle = props.pagestyle;

    return (
        <Typography style={{ color: pageStyle.foregroundColor, fontFamily: "Work Sans", fontSize: "0.825rem", marginBottom: "15px" }}>
            Created with <FontAwesomeIcon icon={faCloudSun} style={{ color: pageStyle.accentColor }} /> Hope and <FontAwesomeIcon icon={faReact} style={{ color: "#61DBFB" }} /> React.
        </Typography>
    );

}