import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, } from '@fortawesome/free-solid-svg-icons';
import { Grid, Typography, Paper, FormControl, Button, OutlinedInput, Popper, Snackbar, Fade, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { CheckCircle } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';


export default function WebFeedback(props) {

    
    var endpoint = "https://tanmoysg.com/backend/connect"

    var currentTime = new Date();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var messageTimestamp = currentTime.getDate() + " " + months[currentTime.getMonth()] + ", " + currentTime.getFullYear() + " @ " + currentTime.toLocaleTimeString();

    const [feedback, setFeedback] = useState({
        userExperienceRating: "",
        designRating: "",
        userFeedback: "",
        feedbackTimestamp: messageTimestamp
    });

    const [openConfirmation, setopenConfirmation] = useState(false);

    const handleClick = () => {
        setFeedback({
            userExperienceRating: "",
            designRating: "",
            userFeedback: "",
            feedbackTimestamp: messageTimestamp
        });
        setopenConfirmation(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setopenConfirmation(false);
    };


    const submitFeedback = (e) => {
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "add-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "feedback",
                    "data": feedback
                }
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            handleClick();
        })
    }

    const pageStyle = props.pagestyle;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const toggleFeedbackPortal = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const StyledRating = withStyles({
        iconFilled: {
            color: '#ff6d75',
        },
        iconHover: {
            color: '#ff3d47',
        },
    })(Rating);

    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon />,
            label: 'Very Dissatisfied',
        },
        2: {
            icon: <SentimentDissatisfiedIcon />,
            label: 'Dissatisfied',
        },
        3: {
            icon: <SentimentSatisfiedIcon />,
            label: 'Neutral',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon />,
            label: 'Satisfied',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon />,
            label: 'Very Satisfied',
        },
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    IconContainer.propTypes = {
        value: PropTypes.number.isRequired,
    };

    console.log(feedback);

    return (
        <div >
            <Typography style={{ fontSize: '1rem', fontFamily: 'Work Sans', fontWeight: '400', color: pageStyle.foregroundColor }}>Feedback helps me grow.</Typography>
            <Button variant="contained" onClick={toggleFeedbackPortal} size="small" style={{ backgroundColor: pageStyle.backgroundColor, fontFamily: 'Work Sans', fontWeight: '400', color: pageStyle.foregroundColor }}>
                <FontAwesomeIcon icon={faCommentDots} style={{ marginRight: '5px', color: pageStyle.accentColor }} />
        Submit Feedback
        </Button>
            <Popper open={open} anchorEl={anchorEl} placement="top-start" transition >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper style={{ boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.43)', border: ".75px solid" + pageStyle.foregroundColor, padding: '20px', borderRadius: '5px', fontFamily: 'Work Sans', backgroundColor: pageStyle.backgroundColor, width: '100%', marginTop: "5px", marginBottom: "5px" }} elevation={5}>
                            <Grid container direction="row" justify="space-between" alignItems="center" style={{ width: "100%" }}>
                                <Grid item>
                                    <Typography style={{ color: pageStyle.foregroundColor, fontFamily: 'Work Sans', fontSize: "1.3rem", fontWeight: "500" }}>Feedback</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton aria-label="close" onClick={toggleFeedbackPortal} >
                                        <CancelIcon style={{ color: pageStyle.accentColor }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <form noValidate autoComplete="off">
                                <Grid container direction="column" justify="center" alignItems="flex-start">
                                    <Grid item style={{ marginBottom: "5px" }}>
                                        <FormControl>
                                            <Typography style={{ marginBottom: "5px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }} >
                                                Rate your Experience
                                    </Typography>
                                            <Rating
                                                name="user-exp"
                                                defaultValue={0}
                                                getLabelText={(value) => customIcons[value].label}
                                                IconContainerComponent={IconContainer}
                                                onChange={e => setFeedback({ ...feedback, userExperienceRating: e.target.value })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginBottom: "5px" }}>
                                        <FormControl>
                                            <Typography style={{ marginBottom: "5px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }} >
                                                Rate the UI Design
                                    </Typography>
                                            <Rating
                                                name="ui-design"
                                                defaultValue={0}
                                                getLabelText={(value) => customIcons[value].label}
                                                IconContainerComponent={IconContainer}
                                                onChange={e => setFeedback({ ...feedback, designRating: e.target.value })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item style={{ marginBottom: "10px" }}>
                                        <FormControl>
                                            <Typography style={{ marginBottom: "5px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }} >
                                                Suggestions
                                    </Typography>
                                            <OutlinedInput
                                                name="feedback"
                                                multiline
                                                rows={3}
                                                style={{ fontFamily: "Work Sans", fontWeight: "300" }}
                                                onChange={e => setFeedback({ ...feedback, userFeedback: e.target.value })}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" onClick={submitFeedback} size="small" style={{ fontFamily: 'Work Sans', fontWeight: '400', color: pageStyle.foregroundColor }}>
                                            <CheckCircle style={{ color: pageStyle.accentColor, marginRight: "5px" }} />
                                    Submit Feedback
                                </Button>
                                    </Grid>
                                </Grid>
                            </form>

                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Snackbar open={openConfirmation} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info">
                    Thank You for the feedback!
                </Alert>
            </Snackbar>
        </div>
    );
}