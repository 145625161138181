import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faUniversity, faSchool, faChevronCircleDown, faInfoCircle, faTimesCircle, faRibbon, faBook, faBinoculars, faAward, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Button, Dialog, DialogActions, DialogTitle, DialogContent, Hidden, Paper } from "@material-ui/core";

export default function Education(props) {

    const [educations, setEducations] = useState();

    useEffect(() => {
        var edu = [];

        Object.keys(props.data).map((education, e) => {
            if (props.data[education].degree.toString().toLowerCase().includes('bachelor') || props.data[education].degree.toString().toLowerCase().includes('master')) {
                delete props.data[education]['_id'];
                edu.push({ category: 'university', ...props.data[education] })
            } else {
                delete props.data[education]['_id'];
                edu.push({ category: 'school', ...props.data[education] })
            }
        })
        setEducations(edu);
        /*
        return () => {
            cleanup
        }*/
    }, [])

    const pageStyle = props.cardstyle;

    const [open, setOpen] = React.useState('none');

    return (
        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography style={{ fontSize: '1.5rem', margin: '0px 0px 15px 0px', fontFamily: 'Montserrat', fontWeight: '400', color: pageStyle.foregroundColor }}>
                    <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '3px', color: pageStyle.accentColor }} /> Education
                </Typography>
                <Grid container direction="row" alignItems="stretch" justify="flex-start" spacing={2}>
                    {
                        educations ? educations.map((education) => {
                            return (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Paper style={{ padding: '20px', borderRadius: '7.5px', fontFamily: 'Work Sans', backgroundColor: pageStyle.backgroundColor, height: '100%', width: '100%' }} elevation={3}>
                                        <Grid container direction="column" alignItems="center" justify="space-between" spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FontAwesomeIcon icon={education.category === 'university' ? faUniversity : faSchool} style={{ fontSize: "2.25rem", height: '2.25rem', width: "2.25rem", padding: "0.5rem", color: pageStyle.foregroundColor, border: "solid 1.5px " + pageStyle.accentColor, borderRadius: "7.5px" }} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography style={{ textAlign: "center", fontSize: "15px", lineHeight: "1", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                    {education.institute}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography style={{ textAlign: "center", fontSize: "12.5px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                    {education.batch}
                                                </Typography>
                                                <Typography style={{ textAlign: "center", fontSize: "20px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '600' }}>
                                                    {education.degree}
                                                </Typography>
                                                <Typography style={{ textAlign: "center", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                    {education.stream}
                                                </Typography>
                                            </Grid>
                                            {/* Small Screen Devices - Expansion Panel/Accordion */}
                                            <Hidden mdUp>
                                                <Grid item style={{ backgroundColor: pageStyle.backgroundColor, boxShadow: "none", width: "100%", margin: "0px" }}>
                                                    <Accordion style={{ backgroundColor: pageStyle.backgroundColor, boxShadow: "none", width: "100%" }}>
                                                        <Grid container direction="row">
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                <AccordionSummary>
                                                                    <Typography style={{ float: "center", textAlign: "center", fontSize: "12.5px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                        More <FontAwesomeIcon style={{ fontSize: "12.5px", color: pageStyle.accentColor }} icon={faChevronCircleDown} />
                                                                    </Typography>
                                                                </AccordionSummary>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
                                                        </Grid>
                                                        <AccordionDetails>
                                                            <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                <div>
                                                                    <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                        <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBookOpen} />
                                                                        Coursework
                                                                    </Typography>
                                                                    <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                        {education.coursework}
                                                                    </Typography>
                                                                </div>
                                                                <div style={{ marginTop: "10px" }}>
                                                                    <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                        <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBinoculars} />
                                                                        Activities
                                                                    </Typography>
                                                                    <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                        {education.activities}
                                                                    </Typography>
                                                                </div>
                                                                <div style={{ marginTop: "10px" }}>
                                                                    <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                        <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faAward} />
                                                                        Achievements
                                                                    </Typography>
                                                                    <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                        {education.achievements}
                                                                    </Typography>
                                                                </div>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            </Hidden>
                                            {/* Small Screen Devices - Expansion Panel/Accordion */}
                                            {/* Large Screen Devices - Dialog Box */}
                                            <Hidden smDown>
                                                <Button onClick={() => { setOpen(education.degree) }}>
                                                    <Typography style={{ textAlign: "center", fontSize: "12.5px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>Know More <FontAwesomeIcon style={{ fontSize: "12.5px", color: pageStyle.accentColor }} icon={faInfoCircle} /></Typography>
                                                </Button>
                                                <Dialog onClose={() => { setOpen('none') }} open={education.degree === open ? true : false} >
                                                    <DialogTitle id="customized-dialog-title" onClose={() => { setOpen('none') }} style={{ backgroundColor: pageStyle.backgroundColor, textAlign: "center" }} >
                                                        <FontAwesomeIcon icon={education.category === 'university' ? faUniversity : faSchool} style={{ fontSize: "2.25rem", height: '2.25rem', width: "2.25rem", padding: "0.5rem", color: pageStyle.foregroundColor, border: "solid 1.5px " + pageStyle.accentColor, borderRadius: "7.5px" }} />
                                                        <Typography style={{ textAlign: "center", fontSize: "17.5px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '400' }}>
                                                            {education.institute}
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent dividers style={{ backgroundColor: pageStyle.backgroundColor }} >
                                                        <Typography style={{ textAlign: "center", fontSize: "12.5px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                            {education.batch}
                                                        </Typography>
                                                        <Typography style={{ textAlign: "center", fontSize: "20px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '600' }}>
                                                            {education.degree}
                                                        </Typography>
                                                        <Typography style={{ textAlign: "center", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                            {education.stream}
                                                        </Typography>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBookOpen} />
                                                                Coursework
                                                            </Typography>
                                                            <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                {education.coursework}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBinoculars} />
                                                                Activities
                                                            </Typography>
                                                            <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                {education.activities}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faAward} />
                                                                Achievements
                                                            </Typography>
                                                            <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                {education.achievements}
                                                            </Typography>
                                                        </div>
                                                        <Hidden xsUp>
                                                            <div style={{ marginTop: "10px" }}>
                                                                <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={2}>
                                                                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9} >
                                                                        <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                            Achievements
                                                                    </Typography>
                                                                        <Typography style={{ textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                            {education.achievements}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
                                                                        <Typography style={{ margin: "0 0 5px 0", textAlign: "justify", fontSize: "15px", fontFamily: 'Work Sans', color: pageStyle.accentColor, fontWeight: '500' }}>
                                                                            Score
                                                                    </Typography>
                                                                        <Typography style={{ textAlign: "justify", fontSize: "2.5rem", lineHeight: '1', fontFamily: 'Work Sans', color: pageStyle.foregroundColor, fontWeight: '400' }}>
                                                                            {education.grades}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Hidden>
                                                    </DialogContent>
                                                    <DialogActions style={{ backgroundColor: pageStyle.backgroundColor }} >
                                                        <Button autoFocus onClick={() => { setOpen('none') }} style={{ fontSize: "12.5px", color: pageStyle.foregroundColor, fontFamily: 'Work Sans' }}>
                                                            Close <FontAwesomeIcon style={{ marginLeft: "3px", color: pageStyle.accentColor }} icon={faTimesCircle} />
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </Hidden>
                                            {/* Large Screen Devices - Dialog Box */}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            );
                        }) : undefined
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}