import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal, faDatabase, faLayerGroup, faMeteor, faCog } from '@fortawesome/free-solid-svg-icons';
import { Chip, Typography, Hidden, Tooltip, ClickAwayListener } from "@material-ui/core";

export default function SkillPopTarts(props) {

    const popTartStyle = props.poptartstyle;
    const popTartData = props.poptartdata;

    const popTartIcons = {
        "Coding": faTerminal,
        "Research": faMeteor,
        "Development": faLayerGroup,
        "Database": faDatabase,
        "others": faCog
    }

    const [open, setOpen] = useState(false);

    return (
        <>
            <Hidden mdUp>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Tooltip title={popTartData.expertise} arrow open={open}>
                        <Chip
                            label={
                                <Typography style={{ fontSize: '0.75rem', fontFamily: 'Work Sans', color: popTartStyle.foregroundColor }}>
                                    <FontAwesomeIcon variant="subtitle2" icon={popTartIcons[popTartData.type]} style={{ color: popTartStyle.accentColor, marginRight: "5px" }} />
                                    {popTartData.skill}
                                </Typography>
                            }
                            style={{
                                fontSize: '0.5rem',
                                fontFamily: 'Work Sans',
                                margin: "5px 7px 5px 0",
                                color: popTartStyle.foregroundColor,
                                backgroundColor: popTartStyle.backgroundColor,
                                borderRadius: "9px",
                                boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.43)'
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    </Tooltip>
                </ClickAwayListener>
            </Hidden>
            <Hidden smDown>
                <Chip
                    label={
                        <Typography style={{ fontSize: '0.75rem', fontFamily: 'Work Sans', color: popTartStyle.foregroundColor }}>
                            <FontAwesomeIcon variant="subtitle2" icon={popTartIcons[popTartData.type]} style={{ color: popTartStyle.accentColor, marginRight: "5px" }} />
                            {popTartData.skill}  <span style={{ fontWeight: "600", color: popTartStyle.accentColor }}>/</span> {popTartData.expertise}
                        </Typography>
                    }
                    style={{
                        fontSize: '0.5rem',
                        fontFamily: 'Work Sans',
                        margin: "5px 7px 5px 0",
                        color: popTartStyle.foregroundColor,
                        backgroundColor: popTartStyle.backgroundColor,
                        borderRadius: "9px",
                        boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.43)'
                    }}
                />
            </Hidden>
        </>
    );
}