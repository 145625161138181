import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faBeer, faHeart, faPlayCircle, faQuoteLeft, faRecordVinyl, faRocket, faSmile } from '@fortawesome/free-solid-svg-icons';
import { Grid, Typography, Paper, Link } from "@material-ui/core";


export default function Muse(props) {

    const pageStyle = props.pagestyle;

    const [muse, setMuse] = useState(props.data);

    useEffect(() => {

    }, [])

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
                <Typography style={{ color: pageStyle.foregroundColor, fontFamily: "Work Sans", fontSize: "0.75rem" }}>
                    <FontAwesomeIcon icon={faHeart} style={{ marginRight: '7px', color: pageStyle.accentColor }} />
                    My Muse
                </Typography>
            </Grid>
            <Grid item>
                <Typography style={{ fontWeight: "900", fontSize: "1.25rem", color: pageStyle.accentColor, fontFamily: "Work Sans" }}>/</Typography>
            </Grid>
            <Grid item>
                <Link target="_blank" underline="none" href={muse.blogLink} >
                    <Paper style={{ padding: "10px 15px", backgroundColor: pageStyle.backgroundColor, borderRadius: "10px" }} elevation={3}>
                        <Typography style={{ color: pageStyle.foregroundColor, fontFamily: "Work Sans", fontSize: "0.75rem" }}>
                            <FontAwesomeIcon icon={faAlignLeft} style={{ marginRight: '7px', color: pageStyle.accentColor }} />
                            {muse.blogText}
                        </Typography>
                    </Paper>
                </Link>
            </Grid>
            <Grid item>
                <Link target="_blank" underline="none" href={muse.quoteLink} >
                    <Paper style={{ padding: "10px 15px", backgroundColor: pageStyle.backgroundColor, borderRadius: "10px" }} elevation={3}>
                        <Typography style={{ color: pageStyle.foregroundColor, fontFamily: "Work Sans", fontSize: "0.75rem" }}>
                            <FontAwesomeIcon icon={faQuoteLeft} style={{ marginRight: '7px', color: pageStyle.accentColor }} />
                            {muse.museQuote}
                        </Typography>
                    </Paper>
                </Link>
            </Grid>
            <Grid item>
                <Link target="_blank" underline="none" href={muse.playlistLink} >
                    <Paper style={{ padding: "10px 15px", backgroundColor: pageStyle.backgroundColor, borderRadius: "10px" }} elevation={3}>
                        <Typography style={{ color: pageStyle.foregroundColor, fontFamily: "Work Sans", fontSize: "0.75rem" }}>
                            <FontAwesomeIcon icon={faRecordVinyl} style={{ marginRight: '7px', color: pageStyle.accentColor }} />
                            {muse.playlistTitle}
                        </Typography>
                    </Paper>
                </Link>
            </Grid>
        </Grid>
    );

}