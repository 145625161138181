import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Button, Grid, Typography, Hidden, Paper, Link } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import Emoji from "./emoji.component";
import dp from "../assets/cover.jpeg";
import styles from '../assets/custom-styling.css';

function Home(props) {

    const pageStyle = props.pagestyle;
    

    var endpoint = "https://tanmoysg.com/backend/connect"

    const [socials, setSocials] = useState();
    const [profile, setProfile] = useState(props.profile);

    useEffect(() => {
        setProfile(props.profile);
        fetch(endpoint, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "content_type": "application/json",
            },
            body: JSON.stringify({
                "action": "get-data",
                "payload": {
                    "database": "tsgOnWebData",
                    "collection": "social"
                }
            })
        }).then(res => {
            return res.json();
        }).then(json => {
            var data = json.data;
            var social = {};
            Object.keys(data).forEach(function (key) {
                if (data[key].service === 'LinkedIn') {
                    social['linkedin'] = {
                        username: data[key].username,
                        link: data[key].link
                    }
                } else if (data[key].service === 'GitHub') {
                    social['github'] = {
                        username: data[key].username,
                        link: data[key].link
                    }
                }
            });
            setSocials(social);
        });


        /*return () => {
            cleanup
        }*/
    }, [props.profile])

    return (
        <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
                {/* For Larges screen Devices */}
                <Hidden smDown>
                    <Grid container direction="column" justify="space-around" alignItems="stretch" spacing={2}>
                        <Typography className="awesome" style={{ fontSize: '115px', fontFamily: 'Montserrat', fontWeight: "700", lineHeight: '1.25em' }}>hi.</Typography>
                        <Link variant="subtitle2" href={socials !== undefined ? socials.linkedin.link : 'https://linkedin.com'} underline="none" target="_blank"><Paper style={{ fontFamily: 'Montserrat', textAlign: "center", paddingTop: '7px', paddingBottom: '7px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "10px", marginBottom: '5px' }}> <FontAwesomeIcon variant="subtitle1" icon={faLinkedin} style={{ color: pageStyle.accentColor, marginRight: "5px" }} elevation={3} /> {socials !== undefined ? socials.linkedin.username : 'LinkedIn'} </Paper> </Link>
                        <Link variant="subtitle2" href={socials !== undefined ? socials.github.link : 'https://github.com'} underline="none" target="_blank"><Paper style={{ fontFamily: 'Montserrat', textAlign: "center", paddingTop: '7px', paddingBottom: '7px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "10px", marginTop: '5px' }}> <FontAwesomeIcon variant="subtitle1" icon={faGithub} style={{ color: pageStyle.accentColor, marginRight: "5px" }} elevation={3} /> {socials !== undefined ? socials.github.username : 'GitHub'} </Paper></Link>
                    </Grid>
                </Hidden>
                {/* For Small screen Devices */}
                <Hidden mdUp>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography className="awesome" style={{ fontSize: '115px', fontFamily: 'Montserrat', fontWeight: "700", lineHeight: '1.15em' }}>hi.</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" justify="space-between" alignItem="center">
                                <Link variant="subtitle2" href={socials !== undefined ? socials.linkedin.link : 'https://linkedin.com'} underline="none" target="_blank"><Paper style={{ fontFamily: 'Montserrat', textAlign: "center", paddingTop: '7px', paddingBottom: '7px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "10px", marginBottom: '5px' }}> <FontAwesomeIcon variant="subtitle2" icon={faLinkedin} style={{ color: pageStyle.accentColor, marginRight: "5px" }} elevation={3} /> {socials !== undefined ? socials.linkedin.username : 'LinkedIn'} </Paper> </Link>
                                <Link variant="subtitle2" href={socials !== undefined ? socials.github.link : 'https://github.com'} underline="none" target="_blank"><Paper style={{ fontFamily: 'Montserrat', textAlign: "center", paddingTop: '7px', paddingBottom: '7px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "10px", marginTop: '5px' }}> <FontAwesomeIcon variant="subtitle2" icon={faGithub} style={{ color: pageStyle.accentColor, marginRight: "5px" }} elevation={3} /> {socials !== undefined ? socials.github.username : 'GitHub'} </Paper></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                {/* For Larges screen Devices */}
                <Hidden smDown>
                    <div style={{ marginLeft: '7px' }}>
                        <Typography variant="h2" style={{ fontFamily: 'Work Sans' }}>I am  <span style={{ color: pageStyle.foregroundColor, fontWeight: "500", backgroundImage: "linear-gradient(" + pageStyle.backgroundColor + "," + pageStyle.backgroundColor + ")", backgroundRepeat: 'no-repeat', backgroundSize: '100% 0.2em ', backgroundPosition: '0 88%' }}>Tanmoy.</span></Typography>
                        <Typography variant="h5" style={{ color: "#cdcdcd", fontFamily: 'Work Sans', marginTop: "10px", textAlign: "justify" }}>
                            {profile ? profile.profileIntro :
                                <>
                                    <Skeleton style={{ width: "100%" }} animation="wave" />
                                    <Skeleton style={{ width: "100%" }} animation="wave" />
                                    <Skeleton style={{ width: "100%" }} animation="wave" />
                                    <Skeleton style={{ width: "100%" }} animation="wave" />
                                </>
                            }
                        </Typography>
                    </div>
                </Hidden>
                {/* For Mobile Devices */}
                <Hidden mdUp>
                    <Typography variant="h4" style={{ fontFamily: 'Work Sans' }}>I am <span style={{ color: pageStyle.foregroundColor, fontWeight: "500", backgroundImage: "linear-gradient(" + pageStyle.backgroundColor + "," + pageStyle.backgroundColor + ")", backgroundRepeat: 'no-repeat', backgroundSize: '100% 0.6em ', backgroundPosition: '0 88%' }}>Tanmoy.</span></Typography>
                    <Typography style={{ color: "#cdcdcd", fontFamily: 'Work Sans', marginTop: "10px", textAlign: "justify", fontSize: "20px" }}>
                        {profile ? profile.profileIntro :
                            <>
                                <Skeleton style={{ width: "100%" }} animation="wave" />
                                <Skeleton style={{ width: "100%" }} animation="wave" />
                                <Skeleton style={{ width: "100%" }} animation="wave" />
                                <Skeleton style={{ width: "100%" }} animation="wave" />
                            </>
                        }
                    </Typography>
                </Hidden>
            </Grid>
        </Grid>
    );
}

export default Home;

/*

                                   <Typography style={{ color: "#cdcdcd", fontFamily: 'Work Sans', marginTop: "10px", textAlign: "justify", fontSize: "20px" }}>
                        Read my blogs <Link  href='https://blog.tanmoysg.com' underline="none" target="_blank" style={{  padding: '7px', backgroundColor: pageStyle.backgroundColor, color: pageStyle.foregroundColor, borderRadius: "10px" }}>here!</Link>
                    </Typography>

*/